.healthforum-container {
  margin: 0 auto;
  padding: 20px;
  padding-right: 20px;
  overflow-x: auto;
  overflow-y: auto;
  background-size: cover;
  background-position: center;
  background-image: url("../../../public/3734225.jpg");
  height: 100%;
  position: fixed;
  width: 100%;
}



@media  (max-width: 600px){

.healthforum-filter-container {
    display: flex;
    align-items: baseline;
    justify-content: space-evenly;
    margin-bottom: 20px;
    padding: 2%;
    margin-bottom: 20px;
    padding: 2%;
    flex-wrap: wrap;
  }
  .healthforum-pagination-container label{
    font-size: 12px;
  }


.healthforum-filter-container select{
  width: 30%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 15px;
  font-size: 18px;
  height: 20px;
}

.healthforum-filter-container input{
  width: 30%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 15px;
  font-size: 18px;
  height: 20px;
}

.healthforum-heading {
  text-align: center;
  margin-bottom: 20px;
  padding-top: 3%;
  color: black;
    width: 300px;
    margin: 0 auto;
    padding: 20px;
    margin-top: 50px;
    color: white;
    background-color: rgb(3 15 39 / 96%);
    border-radius: 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}


}



.healthforum-pagination-container label{
  width: 30%;
  padding: 10px;
  font-size: 18px;
  height: 40px;
  color: white;
}

.healthforum-pagination-container select{
  width: 25%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 40px;
}

.healthforum-heading {
  text-align: center;
  margin-bottom: 20px;
  padding-top: 3%;
  color: black;
    width: 350px;
    margin: 0 auto;
    padding: 20px;
    margin-top: 50px;
    color: white;
    background-color: rgb(3 15 39 / 96%);
    border-radius: 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}





.healthforum-filter-container {
  display: flex;
  align-items: baseline;
  justify-content: space-evenly;
  margin-bottom: 20px;
  padding: 2%;
  margin-bottom: 20px;
  padding: 2%;
}

.healthforum-filter-container select {
  width: 239px;
  padding: 10px;
  border: 1px solid #ccc;
  font-size: 18px;
  height: 45px;
}

.healthforum-filter-container input{
  width: 400px;
}


.healthforum-filter-container input,
.healthforum-filter-container select{
  width: 20%;
  height: 40px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 15px;
  font-size: 18px;
  font-size: small;
}

.healthforum-filter-container select{
  height: 60px;
  font-size: small;
  width: 20%;
}



.healthforum-filter-container button {
    margin-top: 20px; 
    padding: 10px 20px;
    background-color: 007bff;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    width: 20%;
    height: 60px;
    font-size: inherit;
    /*height: 3rem;*/
    /*font-size: 24px;*/
} 



.healthforum-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
}


.healthforum-card {
  background-color: rgb(28 27 27 / 80%);
  padding: 20px;
  border-radius: 10px;
  margin-right: 20px;
  margin-bottom: 20px;
  display: flex;
  width: 70%;
  color: white;
}

.left-section {
  display: flex;
  align-items: center;
}





.healthforum-pagination-container {
  display: flex;
  justify-content: center;
  padding: 3%;
  margin-bottom: 10%;
}

.healthforum-pagination-buttons button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 5px;
}

.healthforum-description {
  max-height: 3em; /* Adjust as needed for two lines */
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

