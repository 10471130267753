

.message-list-container {
  padding: 10px;
  padding-bottom: 70px;

    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: auto;
}

.compose {
  padding: 10px;
  display: flex;
  align-items: center;
  background: white;
  border-top: 1px solid #eeeef1;
  position: fixed;
  width: -webkit-fill-available;
  bottom: 1px;
}

@supports (backdrop-filter: blur(20px)) {
  .compose {
    border: none;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
  }
}

.compose-input {
  flex: 1;
  border: none;
  font-size: 14px;
  height: 100px;
  width: 50%;

  background: none;
}

.compose-input::placeholder {
  opacity: 0.3;
}

.compose .toolbar-button {
  color: #bbbbbf;
  margin-left: 15px;
}

.compose .toolbar-button:hover {
  color: #99999c;
}

.toolbar-button {
  color: #007aff;
  font-size: 28px;
  transition: all 0.1s;
}

.toolbar-button:hover {
  cursor: pointer;
  color: #0063ce;
}

.toolbar-button:active {
  color: #007aff;
  opacity: 0.25;
}

.SendMessage{
    width: fit-content;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
}
.toolbar {
  display: flex;
  align-items: center;

  font-weight: 500;
  border-bottom: 1px solid #eeeef1;

  position: sticky;
  top: 0px;
  background: #00000091;
}

@supports (backdrop-filter: blur(20px)) {
  .toolbar {
    border: none;
    backdrop-filter: blur(20px);
  }
}

.toolbar-title {
  margin: 0;
  font-size: 16px;
  font-weight: 800;
}

.left-items, .right-items {
  flex: 1;
  padding: 10px;
  display: flex;
}

.right-items {
  flex-direction: row-reverse;
}

.left-items .toolbar-button {
  margin-right: 20px;
}

.right-items .toolbar-button {
  margin-left: 20px;
}

.left-items .toolbar-button:last-child,
.right-items .toolbar-button:last-child {
  margin: 0;
}