/* AnalyticsDashboard.css */
.analytics-container{
    margin: 0 auto;
    padding: 20px;
    padding-right: 20px;
    overflow-x: auto;
    overflow-y: auto;
    background-size: cover;
    background-position: center;
    /*background-image: url("../../../public/3734225.jpg");*/
    /*height: 100%;*/
    /*width: 100%;*/
}


@media only screen and (max-width: 600px) {
  .recharts-wrapper {
    width: 50%; /* Set width to 100% for mobile view */
    height: 200px; /* Set height for mobile view */
  }
}



.analytics-dashboard-container {

    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    margin-top: 0px;
    background-color: rgb(3 15 39 / 96%);
    color: white;
    padding: 20px;
    border-radius: 20px;
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .analytics-summary {
    margin-bottom: 20px;
    font-size: 18px;
  }
  
  .analytics-charts {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  
  .analytics-charts h2 {
    margin-bottom: 10px;
  }
  
  .recharts-wrapper {
    width: 100%;
    height: 300px;
  }
  
  .symptoms-analytics {
  margin-top: 50px;
  width: 100%; /* Set width to 100% */
}

.symptoms-table-container {
  overflow-x: auto; /* Add horizontal scrollbar if table overflows */
  width: 100%; /* Set width to 100% */
}

.symptoms-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed; /* Set fixed table layout */
}

.symptoms-table th,
.symptoms-table td {
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #ccc;
  word-wrap: break-word; /* Allow word wrap for long text */
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Show ellipsis for overflow text */
}

.symptoms-table th {
  background-color: #333;
  color: white;
}