.prescription-list {
  background-color: rgb(3 15 39 / 96%);
  color: white;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 50%;
  margin-left: 20%;
}

/* Styles for mobile */
@media (max-width: 767px) {
.prescription-list {
  margin: 0;
  width:  min-content;
  }
    .prescription-list input[type="text"] {
  margin-bottom: 5px;
}
}



.prescription-list h2 {
  text-align: center;
  margin-bottom: 20px;
}

.prescription-list button {
width: auto;
}


.prescription-list table {
  width: 100%;
  border-collapse: collapse;

}

.prescription-list table th,
.prescription-list table td {
  border: 0px solid white;
  padding: 10px;
  text-align: left;

}

/*table th {
  background-color: #f2f2f2;
}*/

/*.prescription-list table tr:nth-child(even) {
  background-color: rgb(3 15 39 / 96%);
}*/

#prescription-list-table tr {
  background-color: rgb(1 71 175);
  border-radius: 20%;
  border: 2px solid black;
}


