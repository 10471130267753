.users-list {
  background-color: rgb(3 15 39 / 96%);
  color: white;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: fit-content;
  margin-left: 25%;
}




/* Styles for mobile */
@media (max-width: 767px) {
.users-list {
  margin-left: 0%;
}
}

.users-list h2 {
  text-align: center;
  margin-bottom: 20px;
}

.users-list button {
width: auto;
}


.users-list table {
  width: 100%;
  border-collapse: collapse;

}

.users-list table th,
.users-list table td {
  border: 0px solid white;
  padding: 10px;
  text-align: left;

}

.users-list tr:hover{
  background-color: black;
}

/*table th {
  background-color: #f2f2f2;
}*/

/*.users-list table tr:nth-child(even) {
  background-color: rgb(3 15 39 / 96%);
}*/

#users-list-table tr {
  background-color: rgb(1 71 175);
  border-radius: 20%;
  border: 2px solid black;
}


