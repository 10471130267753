/* LoginPage.css */



.healthforum-form {
  background-color: rgb(28 27 27 / 80%);
  color: white;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
}

.healthforum-form textarea {
  width: 90%;
    padding: 10px;
    border-radius: 10px;
    border: 1.5px solid #ccc;
    resize: none;
}



.separator {
  margin: 0 5px;
}


.healthforum-form select
{
    width: 95%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    font-size: 15px;
    height: 50px;
}

.healthforum-form h1 {
  margin-bottom: 20px;
  text-align: center;
  color: white; 
}

/* Style the login button */
.healthforum-form button {
  height: 50px;
  font-weight: bold;
  width: 95%;
  padding: 10px;
  margin-top: 10px; 
  border: none;
  border-radius: 10px;
  background-color: #007bff; 
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s; 
}

.healthforum-form button:hover {
  background-color: #0056b3; 
}


