.appointment-container {
  margin: 0 auto;
  padding: 20px;
  padding-right: 20px;
  overflow-x: auto;
  overflow-y: auto;
  background-size: cover;
  background-position: center;
  background-image: url("../../../public/3734225.jpg");
  height: 100%;
  position: fixed;
  width: 100%;

}






.pagination-container label{
  width: 30%;
  padding: 10px;
  font-size: 18px;
  height: 40px;
  color: white;
}

.pagination-container select{
  width: 25%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 18px;
  height: 40px;
}

.doctor-heading {
  text-align: center;
  margin-bottom: 20px;
  padding-top: 4%;
  color: black;
    width: 500px;
    margin: 0 auto;
    padding: 20px;
    margin-top: 50px;
    color: white;
    background-color: rgb(3 15 39 / 96%);
    border-radius: 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

}

.filter-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 2%;
}

.filter-container input,
.filter-container select{
  width: 30%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 15px;
  font-size: 18px;
}




@media only screen and (max-width: 600px) {

    .filter-container input,
    .filter-container select{
    height: 20px;
    font-size: small;

    }
    .filter-container select{
      height: 50px;
    }
}






/* Styles for mobile */
@media (max-width: 767px) {
  .doctor-list {
    width: max-content;
  }
  strong { 
    font-size: 10px;
  }
  .DoctorProfile label { 
    font-size: 18px
  }

.doctor-heading {
  text-align: center;
  margin-bottom: 20px;
  padding-top: 4%;
  color: black;
  font-size: 17px;
    width: 300px;
    margin: 0 auto;
    padding: 20px;
    margin-top: 50px;
    color: white;
    background-color: rgb(3 15 39 / 96%);
    border-radius: 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

}

}





.doctor-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
}


.doctor-card {
  background-color: rgb(28 27 27 / 80%);
  padding: 20px;
  border-radius: 10px;
  margin-right: 20px;
  margin-bottom: 20px;
  display: flex;
  width: 70%;
  color: white;
}

/* Styles for mobile */
@media (max-width: 767px) {

  .doctor-card{
    width: 80%;
    font-size: small;
  }
}

.left-section {
  display: flex;
  align-items: center;
}

.right-section {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  justify-content: center;
}

.right-section {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    align-content: stretch;

}
.right-section button {
  padding: 10px 20px;
  /*font-size: 16px;*/
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
  height: fit-content;
}



.pagination-container {
  display: flex;
  justify-content: center;
  padding: 3%;
}

.pagination-buttons button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 5px;
}

