.messenger {
  display: grid;
  width: 100%;
  height: 100vh;
  /*background-color: rgb(3 15 39 / 72%);*/


  grid-template-columns: 350px auto;
  grid-template-rows: 60px auto 60px;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
}


/* Styles for mobile */
@media (max-width: 767px) {
  .messenger {
    grid-template-columns: 170px auto;
  }
  .bubble {
    font-size: 10px;
  }
  .message .timestamp {
  font-size: 8px;
  }
}

.scrollable {
  position: relative;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  border-radius: 10px;
  margin-top: 70px;

  /*background-color: rgb(28 27 27 / 70%);*/
}

.messengersidebar {
  /*background-color: rgb(28 27 27 / 70%);*/
  grid-row-start: 1;
  grid-row-end: span 3;
  color: white;
  border-radius: 10px;
}



.content {
  /*background-color: rgb(28 27 27 / 70%);*/
  grid-row-start: 1;
  grid-row-end: span 3;
  border-radius: 20px;
}

.footer {
  grid-column-start: 2;
  background-color: white;
}

.messenger {
  height: 100vh;
  font-family: Arial, sans-serif;
  background-image: url('../../../../public/3734225.jpg'); 
  background-size: cover;
}

.welcome{
    background-color: rgb(28 27 27 / 96%);
    color: white;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align horizontally */
  text-align: center; 
}
.welcome h3 {
  text-align: center;
}

.welcome img {
  align-content: center;
}
