/* AboutUsPage.css */
.about-container{
    margin: 0 auto;
    padding-top: 20px;
    overflow-x: auto;
    overflow-y: auto;
    background-size: cover;
    background-position: center;
    background-image: url("../../../../public/3734225.jpg");
    height: 100%;
    position: fixed;
    width: 100%;
}

.about-us-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    margin-top: 50px;
    background-color: rgb(3 15 39 / 96%);
    color: white;
    padding: 20px;
    border-radius: 20px;
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .section {
    margin-bottom: 40px;
  }
  
  .team-members {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    grid-gap: 90px;
  }
  
  .team-member {
    text-align: center;
  }
  



@media only screen and (min-width: 601px) {

  .team-member img {
    width: 150px;
    border-radius: 50%;
    margin-bottom: 10px;
  }

    
}


@media only screen and (max-width: 600px) {
.team-member img {
    width: 100px;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  .team-members {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
    grid-gap: 20px;
  }
}


