/* ForgotPassword.css */


.forgot-password-form {
  width: 400px;
  background-color: rgb(28 27 27 / 80%);
  color: white;
  padding: 20px;
  border-radius: 25px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.forgot-password-form h1 {
  text-align: center;
  margin-bottom: 20px;
  color: white;
}

.forgot-password-form label {
  font-weight: 250;
  font-weight: bold;
}

.forgot-password-form button {
    height: 50px;
  font-weight: bold;
    border-radius: 10px;
  width: 95%;
  background-color: #007bff;
}

.forgot-password-form button:hover {
  background-color: #0056b3; /* Change hover background color to your preferred color */
}
