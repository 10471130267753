.home-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: Arial, sans-serif;
  /*background-image: url('../../../public/3734225.jpg'); */
  background-image: url('../../../public/home.jpg'); 
  background-size: cover;
  padding: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-position: center;
}


@media only screen and (max-width: 600px) {
  .home-container {
    background-size:  90%;
    background-position: top;
    background-size: contain; /* Adjust background size for mobile */
    padding: 10px; /* Adjust padding for mobile */
  }

  .content-wrapper img {
  width: 40px ; 
  height: 40px ;
}

}

@media only screen and (min-width: 601px) {

.content-wrapper img {
  width: 50px ; 
  height: 50px ;
}
}

.quote-container {
  background-color: #fff;
  border-radius: 30px;
  padding: 20px;
  margin-top: 1300px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}


@media only screen and (max-width: 600px) {
  .quote-container {
  margin-top: 400px;
  }
}

.heading {
  font-size: 40px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.content-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
}

.box {
  border-radius: 30px;
  padding: 20px;
  background: #eeeeec;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  flex: 1;
  margin: 10px;
}


@media only screen and (max-width: 600px) {
  .box {
    width: 50%;
    padding: 22px;
    float: left;
    box-sizing: border-box;
  }

  .box p {
    font-size: 13px;
  }
}

.box h2 {
  color: #333;
  /*font-size: 20px;*/
  margin-bottom: 10px;
}

.box p {
  color: #504f4f;
  /*font-size: 16px;*/
}





/* IconsRow.css */
.icon-container{
  margin-top: 10px;
  margin-left: 80px;
  margin-right: 80px;
}

.icons-row {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.icon-container {
  text-align: center;
}

.icon {
  font-size: 3rem;
  color: #007bff; /* Adjust icon color */
}

.role {
  display: block;
  font-size: 1rem;
  color: #333333; /* Adjust role text color */
  margin-top: 8px;
  font-weight: bold;
}
