/* LoginPage.css */



.admin-login-form {
  background-color: rgb(28 27 27 / 80%);
  color: white;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}


.admin-form-group {
  margin-bottom: 20px;
}


.admin-login-form h1 {
  margin-bottom: 20px;
  text-align: center;
  color: white; /* Change color to your preferred highlight color */
}

/* Style the login button */
.admin-login-form button {
  font-weight: bold;
  width: 95%;
  padding: 10px;
  margin-top: 10px; /* Add margin to separate from form fields */
  border: none;
  border-radius: 10px;
  background-color: #007bff; /* Change background color to your preferred color */
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s; /* Add transition for hover effect */
}

.admin-login-form button:hover {
  background-color: #0056b3; /* Change hover background color to your preferred color */
}
