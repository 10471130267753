.symptoms-container{

    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url("../../../public/3734225.jpg");
    background-size: cover;
    background-position: center;
    overflow-x: auto;
    overflow-y: auto;

}

.symptomchecker-container {

  margin-top: 7%;
  background-color: rgb(28 27 27 / 80%);
  color: white;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
}

/* Styles for mobile */
@media (max-width: 767px) {
.symptomchecker-container select  {
    font-size: 12px;
  }

}
.scrollable-container {
  max-height: 300px; /* Set the maximum height of the container */
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 10px; /* Optional: Add padding for spacing */
}



.symptomchecker-container select{
    width: 90%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    /*font-size: 15px;*/
    height: 50px;
}
.symptomchecker-container button{
  width: 50%;
}

.disclaimer{
    margin-top:  2%;
    background: white;
    color: black;
    border-radius: inherit;
    padding: 2%;
}
.added-symptoms-list p{
  padding: 10%;
}
h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.symptomchecker-container label {
  font-size: 16px;
  margin-right: 10px;
}

.symptomchecker-container select {
  /*font-size: 16px;*/
  padding: 5px;
  margin-right: 10px;
}

.symptomchecker-container button {
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}

.symptomchecker-container button:hover {
  background-color: #0056b3;
}

.symptomchecker-container ul {
  list-style: none;
  padding: 0;
}

.symptomchecker-container li {
  margin-bottom: 5px;
}

.symptomchecker-container li button {
  margin-left: 10px;
  background-color: #dc3545;
}

.symptomchecker-container li button:hover {
  background-color: #c82333;
}
