.message {
  display: flex;
  flex-direction: column;
}

.message .timestamp {
  display: flex;
  color: #05204c;
  font-weight: 600;
  font-size: 12px;
  margin: 10px 0px;
  text-transform: uppercase;

}

.message.mine .timestamp {
  justify-content: right;
}

.message .bubble-container {
  font-size: 14px;
  display: flex;
}

.message.mine .bubble-container {
  justify-content: flex-end;

}



.message .bubble-container .bubble {
  margin: 1px 0px;
  background: #f4f4f8;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 75%;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  justify-content: left;
}

.message.mine .bubble-container .bubble {
  background: #007aff;
  color: white;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  justify-content: right;
}
