/* IncidentResponse.css */

.compliance-response-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: rgb(3 15 39 / 96%);
    color: white;
    padding: 20px;
    border-radius: 20px;
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .compliance-list {
    margin-top: 20px;
  }
  
  .compliance-details {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 20px;
    background-color: rgb(3 15 39 / 100%)
  }
  
  .compliance-details h3 {
    margin-top: 0;
    margin-bottom: 5px;
  }
  
  .compliance-details p {
    margin: 5px 0;
  }
  
  .compliance-details button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    width: 95%;
  }
  
  .compliance-details button:hover {
    background-color: #0056b3;
  }
  
  .compliance-details {
  margin-bottom: 20px;
}

.comment {
  margin-bottom: 5px;
}

.comment-user {
  font-weight: bold;
}

.comment-message {
  margin-left: 10px;
}

