.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  font-weight: bold;
  background-color: rgb(0 0 0 / 70%);
}

.logo-container {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
}



.logo-text {
  margin-left: 10px; /* Add margin to separate logo from text */
}

.nav-links {
  display: flex;
  align-items: right;
}

.nav-links a {
  margin: 0 10px;
  color: white;
  text-decoration: none;
}

.nav-links a:hover {
  text-decoration: underline;
}

.navbar a {
  text-decoration: none;
  color: #fff;
}

.navbar a:hover {
  color: #ddd; 
} 

.navbar i {
  margin-right: 5px;
}


