

.notification-container{
     display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    color: white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    font-weight: bold;
    margin-top: 10%;
flex-direction: column;
}


.notification {
  background-color: rgb(3 15 39 / 96%);
  color: white;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 350px;
  width: -webkit-fill-available;
}



.notification h2 {
  margin-bottom: 20px;
  /*font-size: 30px;*/
  color: white;
  text-align: center;

}

.notification label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: white;
  font-size: 105%;
}


.notification button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.notification button:hover {
  background-color: #0056b3;
}

.notification div {
  margin-bottom: 10px;
}

.notification p {
  margin: 10px 0;
}


.email-password {
  border: 1.5px solid #ccc;
  border-radius: 20px;
}

.email-password h3{
text-align: left;
margin-left: 5%;
}

.email-password p{
text-align: left;
margin-left: 5%;
font-size: smaller;
}
