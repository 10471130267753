/* DoctorProfile.css */

.doctor-profile {
  /*background-color: #f4f4f4;*/
  border: 2px solid #ddd;
  padding: 20px;
  border-radius: 15px;
  margin-bottom: 20px;
  width: 85%;
}

.profile-info div {
  margin-bottom: 10px;
}


.DoctorProfile label{
  font-size: 20px;
}